import Container from 'components/layout/Container';
import type { NextPage } from 'next';
import useTranslation from '../hooks/useTranslation';

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../components/ui/card';
import { Badge } from 'components/ui/badge';
import { Button } from 'components/ui/button';
import Link from 'next/link';

const Home: NextPage = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <section className="flex w-full flex-1 flex-col items-center justify-center md:px-[calc(clamp(2.5em,8vw,8em)*2)] mb-16">
        <div>
          <h1 className="text-[calc(clamp(3.25em,7vw,8em)*.875)] font-bold">
            {t('WELCOME')} <span className="font-bold">Ayoub Ouaantar.</span>
          </h1>
          <h2 className="text-m mb-2">{t('WELCOME_INTRO')}</h2>
          <h2 className="text-m mb-2  ">{t('WELCOME_APPROACH')}</h2>
          <h2 className="text-m m-0">{t('WELCOME_CLOSING')}</h2>
        </div>
      </section>
    </Container>
  );
};

export default Home;
